<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12"
      :label="$t('modulos.tipo_envio.filtro.nome')"
      :placeholder="$t('modulos.tipo_envio.filtro.nome')"
      em-filtro
    />
    <input-select
      v-model="filtro.flagVisivelEnvioMaterial"
      class="col-6"
      :label="$t('modulos.tipo_envio.filtro.visivel_envio_material')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.flagVisivelRoteirizacao"
      class="col-6"
      :label="$t('modulos.tipo_envio.filtro.visivel_roteirizacao')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.boleano"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        boleano: helpers.BoleanoEnum,
      },
    };
  },
};
</script>
